<template>
    <div>
        <h2 class="title">{{purposegroup}}</h2>
        <div class="flex flex-auto flex-column flex-wrap gap-1">
            <template v-for="(cha) in m_Charts" :key="cha.pkey" >
                <div class="content-area">
                    <h4>{{cha.caption}}</h4>
                    <Chart :type="cha.type" :data="cha.data" :options="cha.options" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch} from  "vue";
import Chart from 'primevue/chart';
import { useStore } from "vuex";


export default ({
    name: "Analyse",
    components: { Chart },
    props: {
        purposegroup: {
            type: String,
            default: "analyse",
            validator(value) {
                return ['analyse', 'metadata'].includes(value); // The value must match one of these strings
            }
        }
    },
    setup(props) {
        
        const store = useStore();
        const m_Charts = ref([]);

        let m_componentMounted = false;
        let m_componentInitialized = false;
        
        onMounted(() => {
            m_componentMounted = true;
            store.dispatch("refreshCharts"); 
            initWhenReady();
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });
        watch(() => store.getters.charts, function () { showCharts(); });
        watch(() => props.purposegroup, function () { showCharts(); });

        const initWhenReady = async () => {
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;
            showCharts();
        }

        const showCharts = async () =>
        {
            m_Charts.value = store.getters.getChartsByPurposegroup(props.purposegroup);
        }

        return { m_Charts };
    },
})
</script>

<style scoped>

.title:first-letter {
    text-transform: uppercase;
}

</style>

